<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="text-center col-lg-8 col-sm-12">

        <div
          class="centered"
          style="width: 100%; height: 320px"
          :style="{
              background: `linear-gradient(${this.brandColor}, black)`,
            }"
        >
          <img
            v-lazy="
                eventsBrand.metadata.main_image.imgix_url +
                '?w=315&h=200&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            v-bind:alt="eventsBrand.title"
            width="315px"
            height="200px"
            class="d-inline-xs d-sm-none"
          />

          <img
            v-lazy="
                eventsBrand.metadata.main_image.imgix_url +
                '?w=615&h=250&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            v-bind:alt="eventsBrand.title"
            width="615px"
            class="d-none d-sm-inline"
            height="250px"
          />
          <h1
            class="tulum-party-title"
            style="
                color: #ffffff !important;
                margin-top: 20px;
                text-transform: uppercase;
              "
          >
            {{ eventsBrand.title }}
          </h1>
        </div>

        <!--        <img-->
        <!--          v-if="eventsBrand.metadata.logo"-->
        <!--          v-lazy="eventsBrand.metadata.logo.imgix_url"-->
        <!--          height="35px"-->
        <!--          style="margin-bottom: 20px; max-width: 300px"-->
        <!--          :alt="eventsBrand.name"-->
        <!--        />-->


        <br/>
       <div v-if="incomingParties.length === 0">
          <router-link
            :to="{ path: '/l?u=' + instagramProfileLink }"
          >
            <img
              v-lazy="this.logo + '?w=40w=40&fit=clamp'"
              height="40px"
              width="40px"
              :alt="eventsBrand.title"
              style="border-radius: 100px;z-index: 2"
            />
          </router-link>
          <h5 v-if="this.legend">{{ this.legend }}</h5>
        </div>
        <div v-if="incomingParties.length">

          <h5 v-if="this.legend">{{ this.legend }}</h5>

          <router-link
            class="text-left"
            :to="{ path: '/event/' + incomingParties[0].slug}"
          >
            <img
              v-lazy="this.logo + '?w=40w=40&fit=clamp'"
              height="40px"
              width="40px"
              :alt="eventsBrand.title"
              style="border-radius: 100px; margin-right: -20px; z-index: 2"
            />
            <img
              v-lazy="
              incomingParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            "
              :alt="'Upcoming Event: ' + incomingParties[0].title"
              height="40px"
              width="40px"
              style="border-radius: 100px"
            />
            Upcoming Events
            <span class="badge">({{ incomingParties.length }})</span>
          </router-link>
        </div>

<!--        <div v-if="this.$route.path === '/brands/zamna-tulum'"-->
<!--             style="margin-top: 40px"-->
<!--        >-->

<!--          <p>At Zamna Tulum, music, art, and landscapes converge creating a unique out of this world experience.</p>-->

<!--          <router-link :to="{path:'/zamna-tulum-official-sellers'}">-->
<!--            <OfficialSellerCard></OfficialSellerCard>-->
<!--          </router-link>-->
<!--          <br>-->

<!--        </div>-->
        <section  :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
              class="card"
              style=" color: #fff !important; margin-bottom: 1px; padding: 40px; margin-top: 20px; min-height: 400px;">
          <div v-html="eventsBrand.content"></div>

        </section>


        <IncomingParty :incoming-party="incomingParties[0]"
                       :logo="this.logo"
                       :color="this.secondaryColor"></IncomingParty>
        <!--        #2a737a-->
        <section
          class="row"
          v-if="incomingParties.length"
          style="padding: 10px"
          :style="{ background: `linear-gradient(${this.brandColor}, black)` }"
          id="incoming-events"
        >
          <h4 class="section-header text-left">
            <img
              v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
              height="40px"
              width="40px"
              style="border-radius: 300px"
            />Upcoming Events ({{ incomingParties.length }})
          </h4>

          <br v-if="incomingParties.length"/>
          <p style="margin-left: 50px;font-size: 0.6rem;margin-top: -35px">TICKETS & EVENT DETAILS</p>
          <br v-if="incomingParties.length"/>

          <br v-if="incomingParties.length"/>

          <MiniEventCard
            large="true"
            v-bind:event="event"
            with-venue="true"
            v-for="(event, idx) in incomingParties"
            class="card-animation-on-hover animation-transition-general animate-bounce"
            short="true"
            :key="event._id"
            :idx="idx"
          ></MiniEventCard>
          <br>
          <div class="card centered" style="height: 250px;width: 260px;margin-top: 30px"
               :style="{ background: `linear-gradient(${this.brandColor}, black)` }"

          >
            <h4 class="section-header" style="font-weight: bold">
              <i class="tim-icons icon-chat-33"></i> INFO & RSVP
            </h4>
            <img v-lazy="astroImg + '?h=30'" height="30px" width="30px" class="text-center centered"
            />
            <p style="padding: 10px;font-size: 0.8rem">
              Secure Your Spot! Book Your <strong>{{ eventsBrand.title }}</strong> Experience Now.</p>
            <br>
            <RsvpViaDialog
              :legend="this.rsvpLegend"
              text="INFO & RSVP"
              :club-config="{name: eventsBrand.title, logo: eventsBrand.metadata.main_image.imgix_url}">
            </RsvpViaDialog>
            <br>
            <small>event info | tables | rsvp | tickets</small>
            <br>

          </div>
        </section>

        <section>
          <div id="past-parties" v-if="pastParties" style="margin-top: 20px">
            <h4 class="section-header text-left">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                :alt="this.eventsBrand.title"
                style="border-radius: 300px"
              />

              Past Events
            </h4>


            <div class="text-left" v-if="pastParties.length >= 6">

              <router-link
                :key="event.slug"
                :to="{ name: 'Event', params: { slug: event.slug } }"
                v-for="event in pastParties"
              >
                <img
                  class="carrousel-image"
                  v-lazy="
                    event.metadata.main_image.imgix_url + '?w=60&h=60&fit=clamp'
                  "
                  :alt="'PAST EVENTS - ' + event.title"
                  height="60px"
                  width="60px"
                />
              </router-link>
            </div>
            <div class="text-left" v-if="pastParties.length < 6">
              <hr>

              <MiniEventCard
                v-bind:event="event"
                with-venue="true"
                v-for="(event, idx) in pastParties"
                class="card-animation-on-hover animation-transition-general animate-bounce"
                short="true"
                past="true"
                :key="event._id"
                :idx="idx"
              ></MiniEventCard>
            </div>

          </div>
        </section>

        <br/>
        <section class="row d-xl-none text-left" v-if="this.photos">

          <h4 class="section-header">
            <router-link
              :to="{ path: '/l?u=' + instagramProfileLink }"
            >
              <img
                v-lazy="this.instagramImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                :alt="this.title"
              />
              <small>&nbsp;{{ instagramProfile }}</small>

            </router-link>
          </h4>

          <hr
            style="height: 2px"
            :style="{ backgroundColor: this.brandColor }"
          />

          <carrousel :folder="this.photos" :color="this.brandColor">
          </carrousel>

          <div class="card text-center centered" style="max-width: 600px">
            <div v-html="eventsBrand.metadata.description" style="margin-top: 20px"></div>
          </div>
        </section>

        <section v-if="video" style="margin-top: 35px" class="card row"
                 :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
        >
          <br>
          <h4 class="text-left section-header">
            <img
              v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
              :alt="this.eventsBrand.title"

              height="40px"
              width="40px"
              style="border-radius: 300px"
            />&nbsp;ACTION
          </h4>
          <hr
            style="height: 1px; margin-top: -10px"
            :style="{ backgroundColor: this.brandColor }"
          />
          <club-video-album :video="video"></club-video-album>
        </section>


        <section v-if="links.length" class="d-inline"
                 :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }">

          <img v-lazy="this.djImage"
               alt="A DJ presenting the brand links & resources"
               height="100px" class="pull-right">
          <h4 class="section-header text-smart">RESOURCES</h4>
          <br>

          <DynamicLinks :links="links"
                        :style="{ background: `linear-gradient(45deg, black, ${this.brandColor})` }"
          ></DynamicLinks>

          <br>
        </section>


        <section class="card centered "
        >
          <h4 class="section-header text-left" style="font-weight: bold">
            <img
              v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
              height="40px"
              width="40px"
              :alt="eventsBrand.title"
              style="border-radius: 300px"
            />&nbsp;INFORMATION & RESERVATION
          </h4>

          <p
            style="
              color: #fff !important;
              width: 300px;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
            "
          >

            <img
              v-lazy="astroImg + '?h=50'"
              height="50px"
              width="50px"
              alt="tulum party support"
              style="
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    margin-top: 20px;
                  "
            />

            <br>
            <br>
            <strong>Let's the party begin !</strong>

            <br>
            For reservations, or just to enquire,
            A simple message is all we desire.

          </p>
          <Reservations :identifier="this.eventsBrand.slug" :color="this.brandColor"></Reservations>
        </section>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">

        <div class="row">
          <div class="col-sm-12 text-center">
            <h4 class="section-header text-left" style="font-weight: bold">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                :alt="eventsBrand.title"
                style="border-radius: 300px"
              />INFO & RSVP
            </h4>
            <p>Secure Your Spot! Book Your <strong>{{ eventsBrand.title }}</strong> Experience Now.</p>
            <br>
            <RsvpViaDialog
              :legend="this.rsvpLegend"
              text="INFO & RSVP"
              :club-config="{name: eventsBrand.title, logo: eventsBrand.metadata.main_image.imgix_url}"></RsvpViaDialog>
            <br>
            <br>
          </div>

          <br>
        </div>
        <section class="row" v-if="this.photos">
          <h4 class="section-header">
            <router-link
              :to="{ path: '/l?u=' + eventsBrand.metadata.instagram_profile }"
            >
              <img
                v-lazy="this.instagramImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                :alt="'Instagram Logo'"
              />
              <small>&nbsp;{{ instagramProfile }}</small>


            </router-link>
          </h4>
          <carrousel :folder="this.photos" :color="this.brandColor"></carrousel>
        </section>
        <section
          style="margin-top: 30px;"
          :style="{ background: `linear-gradient(${this.brandColor}, black)` }"
        >
          <h4
            id="incoming-events"
            class="section-header"
            v-if="incomingParties.length"
          >
            <img
              v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
              height="40px"
              width="40px"
              style="border-radius: 300px"
            />Upcoming Events
          </h4>

          <MiniEventCard
            v-for="(event, idx) in incomingParties"
            v-bind:event="event"
            class="card-animation-on-hover"
            short="true"
            :without-brand="true"
            :key="event._id"
            :idx="idx"
          ></MiniEventCard>
        </section>


        <br>

        <section v-if="links.length"
                 :style="{ background: `linear-gradient(black, ${this.brandColor})` }"

        >
          <img v-lazy="this.djImage"
               alt="A DJ presenting the brand links & resources"
               height="100px" class="pull-right"
               style="margin-top: -50px">
          <h4 class="section-header">
            RESOURCES
          </h4>
          <DynamicLinks :links="links"
                        :style="{ background: `linear-gradient( 45deg, '#1C1C1F', ${this.brandColor})` }"

          />
        </section>
        <br>
        <section>
          <h4 class="section-header">
            <img v-lazy="this.djImage"
                 alt="A DJ presenting the brand links & resources"
                 height="100px" class="pull-right"
                 style="margin-top: -50px">
            WHAT'S HOT
          </h4>
          <div
            :style="{ background: `linear-gradient( 45deg, '#1C1C1F', ${this.brandColor})` }">
            <BrandCollection></BrandCollection>

          </div>

        </section>
      </aside>
    </article>
  </div>
</template>

<script>
import moment from 'moment';
import Reservations from '@/components/Reservations/Reservations';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import Carrousel from '@/components/Carrousel';
import brandsConfig from '../../common/brandsConfig';
import ClubVideoAlbum from '@/components/VideoAlbum/ClubVideoAlbum';
import EventCard from '@/components/EventCard/EventCard';
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import DynamicLinks from "@/components/DynamicLinks";
import BrandCollection from "@/components/Cards/BrandCollection";
import OfficialSellerCard from "@/pages/OfficialSellers/OfficialSellerCard";
import IncomingParty from "@/pages/Brands/IncomingParty";

export default {
  name: 'Brand',
  props: ['slug'],
  metaInfo() {
    const BrandName = this.slug.replace(/-/g, ' ').toUpperCase().replaceAll('TULUM', '');
    const legend = brandsConfig[this.slug].legend || '';
    const logo = brandsConfig[this.slug].logo || '';
    return {
      title: `${BrandName} · Upcoming Events, Tickets & Information. ${legend}`,
      meta: [
        {
          name: 'description',
          content: `${legend} | Events · Tickets & Information`,
        },
        {
          name: 'keywords',
          content: `Tulum Top Event Tickets Production Brands Event Organizer ${BrandName}`,
        },
        {
          property: 'og:title',
          content: `${BrandName}. Upcoming Events, Tickets and Information`,
        },
        {
          property: 'og:url',
          content: `https://www.tulum.party/brands/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${BrandName} - ${legend}`,
        },
        {property: 'og:type', content: 'website'},
        {
          property: 'og:image',
          content: logo
        }
      ],
    };
  },
  data: () => {
    return {
      instagramImage: 'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
    };
  },
  components: {
    IncomingParty,
    OfficialSellerCard,
    DynamicLinks,
    RsvpViaDialog,
    EventCard,
    MiniEventCard,
    Carrousel,
    ClubVideoAlbum,
    Reservations,
    BrandCollection
  },
  created: function () {
    this.$store.dispatch('getBrand', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: this.slug});
    this.brandConfig = brandsConfig[this.slug];
    this.brandColor = this.brandConfig.color;
    this.secondaryColor = this.brandConfig.secondaryColor;
    this.photos = this.brandConfig.photos;
    this.legend = this.brandConfig.legend;
    this.logo = this.brandConfig.logo;
    this.title = this.brandConfig.title;
  },
  methods: {
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    nextEventDate: {
      get() {
        moment()
        return this.incomingParties[0] && moment(this.incomingParties[0].metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    },
    instagramProfile: {
      get() {
        return this.eventsBrand.metadata &&
          this.eventsBrand.metadata.instagram_profile.split("/")[3]
      }
    },
    instagramProfileLink: {
      get() {
        return this.eventsBrand.metadata &&
          this.eventsBrand.metadata.instagram_profile
      }
    },
    rsvpLegend: {
      get() {
        return `<p>Secure Your Spot! Book Your <strong>${brandsConfig[this.slug].name}</strong> Experience Now.</p>`
      }
    },
    video: {
      get() {
        return (
          brandsConfig[this.slug].video ||
          this.$store.state.brand.metadata.video
        );
      },
    },
    incomingParties: {
      get() {
        let parties = this.$store.state.brand.metadata.past_parties || [];

        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...parties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
    pastParties: {
      get() {
        let parties = this.$store.state.brand.metadata.past_parties || [{metadata: {}}];

        function compareUTCTimes(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay < now;
        }

        // return []
        return [...parties]
          .sort((a, b) => {
            return a.metadata.date < b.metadata.date ? 1 : -1;
          })
          .filter((x) => compareUTCTimes(x));
      },
    },
    brandParties: {
      get() {
        return (
          this.$store.state.brand.parties || [
            {metadata: {main_image: {}, date: null}},
          ]
        );
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    eventsBrand: {
      get() {
        return this.$store.state.brand;
      },
    },
  },
};
</script>
